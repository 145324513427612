<template>
  <div>
    订阅通知
  </div>
</template>

<script>
export default {
  setup () {
    

    return {}
  }
}
</script>

<style lang="less" scoped>

</style>