<template>
  <div class="template-msg">
    <div class="iss-search-wrap">
      <search-form :items="items" @fnSearch="handleFnSearch" />
    </div>
    <div class="iss-grid-wrap mt-20">
      <div class="refresh-btn flex" @click="pullLatestTemplateFun">
        <SyncOutlined class="mr-10" v-if="!loading" />
        <a-spin class="mr-10" size="small" v-else />
        <span>同步微信后台模板</span>
      </div>
      <grid
        ref="gridRef"
        :allow-selection="false"
        :columns="columns"
        :code="$route.path"
        :url="url"
        :url-params="{
           appId: $store.state.account.appId
        }"
        :search="search"
        :btn-operation="btnOperation"
        :scroll="{ x: 1200, y: gridHeight }"
      >
        <template #operation="{ record }">
          <operation :options="options" :record="record" />
        </template>

        <template #massSendRecords="{ record }">
          <div
            style="color: #5979f8; cursor: pointer"
            @click="handleCheack(record)"
          >
            查看
          </div>
        </template>
        <template #templatePreview="{ record }">
          <div style="color: #5979f8">
            <QrcodeOutlined @click="handlePreview(record)" class="fs-24" />
          </div>
        </template>
      </grid>
    </div>
    <!-- 创建模版消息弹框 -->
    <a-modal
      title="创建模版消息"
      v-model:visible="modalVisible"
      width="736px"
      :centered="true"
    >
      <div class="flex templateContent" v-if="templateList.length > 0">
        <div class="flex modal-left">
          <div
            class="tmp-list"
            v-for="(item, index) in templateList"
            :key="index"
            :class="item.id == activeIndex ? 'active' : ''"
          >
            <a-button @click="getTemplateContent(item, index)"
              >{{ item.title }}
            </a-button>
          </div>
          <div class="pagination">
            <a-pagination
              v-model:current="panigation.current"
              simple
              :defaultPageSize="panigation.size"
              :total="panigation.total"
              @change="handleChange"
            />
          </div>
        </div>
        <div class="modal-right">
          <div class="main-content">
            <p class="fs-16">{{ templateContent.title }}</p>
            <div class="content-list">
              <!-- 两级渲染 -->
              <template v-if="templateContentDetail.length > 0">
                <p v-for="(item, key) in templateContentDetail" :key="key">
                  {{ item.value }}
                  <template v-if="item.children && item.children.length">
                    <p
                      v-for="(child, childIndex) in item.children"
                      :key="childIndex"
                    >
                      {{ child.value }}
                    </p>
                  </template>
                </p>
              </template>
            </div>
            <div class="flex" style="flex-direction: column">
              <a-divider style="width: 100%" />
              <div class="flex" style="width: 100%; cursor: pointer">
                <span class="fs-14">点击查看详情</span>
                <RightOutlined />
              </div>
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <a-space>
          <a-button @click="modalVisible = false">取消</a-button>
          <a-button type="primary" @click="handleOk"> 确认 </a-button>
        </a-space>
      </template>
    </a-modal>

    <!-- 消息同步弹框 -->
    <a-modal
      v-model:visible="modalEmptyVisible"
      width="540px"
      :closable="false"
      :centered="true"
    >
      <p style="text-align: center">
        <InfoCircleOutlined style="color: red" class="mr-10 pt-20" /><span
          v-if="isSync"
          >您还未同步微信后台模板，请先同步该公众号的微信后台模板！</span
        >
        <span v-else>
          您的公众号暂无模板数据，请到微信公众平台的模板库中添加您的模板
        </span>
      </p>
      <template #footer>
        <a-space>
          <a-button
            type="primary"
            @click="
              isSync ? pullLatestTemplateFun : (modalEmptyVisible = false)
            "
          >
            确认
          </a-button>
        </a-space>
      </template>
    </a-modal>
    <a-modal
      v-model:visible="limiteModalVisible"
      width="540px"
      :closable="false"
      :centered="true"
    >
      <p style="text-align: center">
        <InfoCircleOutlined style="color: red" class="mr-10 pt-20" /><span
          >您今日发送的模板消息已达上限，请调整发送时间
        </span>
      </p>
      <template #footer>
        <a-space>
          <a-button
            type="primary"
            @click="
              limiteModalVisible = false;
              sendModalVisible = true;
            "
          >
            确认
          </a-button>
        </a-space>
      </template>
    </a-modal>
    <!-- 预览模版消息 -->
    <a-modal
      title="预览模版消息"
      v-model:visible="qrcodeModalVisible"
      width="500px"
      :centered="true"
      @ok="qrcodeModalVisible = false"
    >
      <div class="modal-container">
        <p>微信扫一扫</p>
        <div class="mt-20 mb-20">
          <vue-qr :text="qrcodeText" :margin="0" :size="180" />
        </div>
        <p>此二维码仅供消息预览</p>
      </div>
    </a-modal>

    <!-- 发送记录弹框 -->
    <a-modal
      :title="`${activeRecord.templateName}-${activeRecord.title}`"
      v-model:visible="recordModalVisible"
      width="75%"
      :centered="true"
    >
      <div class="modal-container">
        <a-table
          class="iss-grid"
          v-bind="$attrs"
          size="middle"
          :columns="recordColumns"
          :data-source="recordList"
          :pagination="recordPanigation"
          :loading="recordLoading"
          @change="handleTableChange"
        >
          <template #fanType="{ text }">
            <span v-if="text === 0">全部粉丝</span>
            <span v-if="text === 1">标签粉丝</span>
          </template>
          <template #sendTime="{ text }">
            <span v-if="!text">-</span>
            <span v-else>{{ text }}</span>
          </template>
          <template #sendCount="{ text }">
            <span v-if="!text && text != 0">-</span>
            <span v-else>{{ text }}</span>
          </template>
          <template #successCount="{ text }">
            <span v-if="!text && text != 0">-</span>
            <span v-else>{{ text }}</span>
          </template>
          <template #sendStatus="{ record }">
            <span v-if="record.sendStatus === 0 && record.sendFlag == 1"
              >待发送</span
            >
            <span
              v-else-if="record.sendStatus == 0 && record.sendFlag == 0"
              style="color: #666"
            >
              已取消发送
            </span>
            <span v-else-if="record.sendStatus === 1">发送中</span>
            <span v-else-if="record.sendStatus === 2">发送完成</span>
            <span v-else-if="record.sendStatus === 3" style="color: red">
              发送失败
              <a-tooltip :title="record.errorMsg">
                <InfoCircleOutlined class="mr-10 pt-20" />
              </a-tooltip>
            </span>
          </template>
          <template #successRate="{ record }">
            <span v-if="record.successCount == 0 || record.sendCount == 0"
              >0%</span
            >
            <span v-else-if="!record.successCount || !record.sendCount">-</span>
            <span v-else>
              {{   Math.floor((record.successCount / record.sendCount) * 100)  }}%
            </span>
          </template>
          <template #operation="{ record }">
            <div class="operate-btn">
              <template v-if="record.sendStatus == 2">
                <a-button type="text" @click="checkDetail(record)"
                  >查看数据</a-button
                >
                <a-button type="text" @click="exportRecords(record)"
                  >导出明细</a-button
                >
              </template>
              <template v-else-if="record.sendStatus == 1">
                <span>-</span>
              </template>
              <template
                v-else-if="
                  record.sendTimeType == 1 &&
                  record.sendStatus == 0 &&
                  record.sendFlag == 1
                "
              >
                <a-button type="text" @click="cancleSend(record)"
                  >取消发送</a-button
                >
              </template>
            </div>
          </template>
        </a-table>
      </div>
      <template #footer>
        <a-space>
          <a-button @click="recordModalVisible = false"> 取消 </a-button>
        </a-space>
      </template>
    </a-modal>

    <!-- 发送消息弹框 -->
    <a-modal
      title="发送模版消息"
      v-model:visible="sendModalVisible"
      :centered="true"
      @ok="debouncedSendMessage"
    >
      <a-form
        ref="formRef"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
        class="send-modal"
      >
        <a-form-item label="发送至">
          <a-row type="flex" :gutter="20">
            <a-col :span="12">
              <a-select v-model:value="sendForm.fanType" placeholder="请选择">
                <a-select-option
                  v-for="item in tagTypeOption"
                  :key="item.value"
                  :value="item.value"
                  @click="handleChangeSelect(item)"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col :span="12" v-if="sendForm.fanType == 1">
              <div class="tag-select">
                <div class="flex select-box" @click.stop="show = !show">
                  <span>请选择标签</span> <DownOutlined class="ml-10" />
                </div>
                <div class="tag-list" ref="modalRef" v-if="show">
                  <a-checkbox-group
                    v-model:value="checkedList"
                    @change="handleChangeTag"
                  >
                    <a-checkbox
                      :value="item.id"
                      v-for="item in tagFuneOption"
                      :key="item.id"
                      >{{ item.name }}</a-checkbox
                    >
                  </a-checkbox-group>
                </div>
              </div>
            </a-col>
          </a-row>
        </a-form-item>
        <a-form-item label="已选择标签" v-if="selectList?.length > 0">
          <a-tag
            v-for="(item, index) in selectList"
            :key="index"
            color="#5979F8"
            >{{ item.name }}
            <CloseCircleFilled @click="deleteTag(item.id)" />
          </a-tag>
        </a-form-item>
        <a-form-item label="发送时间">
          <a-radio-group v-model:value="sendForm.sendTimeType">
            <a-radio :value="0">立即发送</a-radio>
            <a-radio :value="1">定时发送</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item
          :wrapper-col="{ span: 16, offset: 6 }"
          v-if="sendForm.sendTimeType == 1"
        >
          <a-date-picker
            v-model:value="sendForm.sendTime"
            placeholder="请选择"
            show-time
            value-format="yyyy-MM-DD HH:mm:ss"
            :disabled-date="disabledDate"
            :disabled-time="disabledTime"
          ></a-date-picker>
        </a-form-item>
      </a-form>
    </a-modal>

    <!-- 发送成功弹框 -->
    <a-modal
      v-model:visible="successModalVisible"
      width="540px"
      :closable="false"
      :centered="true"
    >
      <p style="text-align: center; font-size: 18px">
        <CheckCircleFilled
          style="color: rgb(9, 187, 7)"
          class="mr-10 pt-20"
        /><span style="color: #000000; font-weight: bold">操作成功 </span>
      </p>
      <p style="color: #666666; text-align: center">
        如需查看发送详情，可在群发记录点击“查看数据”查看详情
      </p>
      <template #footer>
        <a-space>
          <a-button type="primary" @click="successModalVisible = false">
            确认
          </a-button>
        </a-space>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { reactive, toRefs, ref, onMounted, onBeforeUnmount } from 'vue';
import SearchForm from '@/components/searchForm';
import Grid from '@/components/grid';
import Operation from '@/components/operation';
import {
  Modal,
  Space,
  pagination,
  Divider,
  message,
  Spin,
  Table,
  Form,
  Radio,
  Checkbox,
  Select,
  Row,
  Col,
  DatePicker,
  Tooltip,
  Tag,
} from 'ant-design-vue';
import wechatApi from '@/api/wechatServer';
import VueQr from 'vue-qr/src';
import moment from 'moment';
import {
  SyncOutlined,
  InfoCircleOutlined,
  RightOutlined,
  QrcodeOutlined,
  DownOutlined,
  CloseCircleFilled,
  CheckCircleFilled,
} from '@ant-design/icons-vue';
import { processString } from '@/utils';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { downloadExcel, debounce } from '@/utils';

export default {
  components: {
    SearchForm,
    Grid,
    Operation,
    VueQr,
    ARow: Row,
    ACol: Col,
    AModal: Modal,
    ASpace: Space,
    ADivider: Divider,
    ASpin: Spin,
    APagination: pagination,
    ATable: Table,
    AForm: Form,
    AFormItem: Form.Item,
    ASelect: Select,
    ASelectOption: Select.Option,
    ARadio: Radio,
    ARadioGroup: Radio.Group,
    ACheckbox: Checkbox,
    ACheckboxGroup: Checkbox.Group,
    ADatePicker: DatePicker,
    ATooltip: Tooltip,
    ATag: Tag,
    SyncOutlined,
    InfoCircleOutlined,
    RightOutlined,
    QrcodeOutlined,
    DownOutlined,
    CloseCircleFilled,
    CheckCircleFilled,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const modalVisible = ref(false);
    const modalEmptyVisible = ref(false);
    const recordModalVisible = ref(false);
    const qrcodeModalVisible = ref(false);
    const sendModalVisible = ref(false);
    const limiteModalVisible = ref(false);
    const successModalVisible = ref(false);
    const modalRef = ref(null);
    const gridRef = ref(null);
    // 是否同步过微信模版消息
    const isSync = ref(false);
    const activeIndex = ref(0);
    const state = reactive({
      show: false,
      qrcodeText: `${process.env.VUE_APP_FORM_CLIENT_URL}/#/wechatTemplateH5?id=`,
      search: {},
      loading: false,
      activeRecord: {},
      tagTypeOption: [
        {
          value: 0,
          label: '全部粉丝',
        },
        {
          value: 1,
          label: '标签粉丝',
        },
      ],
      tagList: [],
      tagFuneOption: [],
      checkedList: [],
      selectList: [],
      timer: null,

    });
    const sendForm = reactive({
      fanType: 0,
      sendTimeType: 0,
      sendTime: moment().format('YYYY-MM-DD HH:mm:ss'),
      tagFunList: [],
    });
    const templateListState = reactive({
      appId: store.state.account.appId,
      panigation: {
        current: 1,
        size: 10,
        total: 0,
      },
      templateList: [],
      templateContent: {},
      templateContentDetail: [],
    });

    const templateRecordListState = reactive({
      recordLoading: false,
      recordPanigation: {
        current: 1,
        size: 10,
        total: 10,
      },
      recordList: [],
      templateMsg: {}, //当前点击查看模版
    });
    const handleClickOutside = event => {
      if (modalRef.value && !modalRef.value.contains(event.target)) {
        state.show = false;
      }
    };
    const _init = async () => {
      document.addEventListener('click', handleClickOutside);
      // await wechatApi.getTenantId().then(res => {
      //   if (!res) {
      //     console.log(res);
      //   } else {
      //     templateListState.appId = res.appId[0].appId;
      //     store.commit('account/setAppId', res.appId[0].appId);
      //   }
      // });
      getTemplateListFun();
    };
    const getTemplateContent = item => {
      activeIndex.value = item.id;
      getTemplateContentFun(item);
    };
    // 同步最新消息模版
    const pullLatestTemplateFun = () => {
      modalEmptyVisible.value = false;
      state.loading = true;
      wechatApi.pullLatestTemplate({}, templateListState.appId).then(res => {
        console.log(res, 444);
        state.loading = false;
        if (!res) {
          console.log(res);
        } else {
          message.success('同步成功');
        }
      });
    };

    // 获取模版内容
    const getTemplateContentFun = item => {
      wechatApi.getTemplateById({}, item.id).then(res => {
        if (!res) {
          console.log(res);
        } else {
          templateListState.templateContent = res;
          templateListState.templateContentDetail = !res.example
            ? []
            : processString(res.example);
          console.log('模版内容', templateListState.templateContentDetail);
        }
      });
    };
    // 获取模版列表
    const getTemplateListFun = () => {
      let data = {
        appId: templateListState.appId,

        ...templateListState.panigation,
      };
      wechatApi.getTemplateList({}, data).then(res => {
        console.log(res, '模版列表');
        if (!res) {
          console.log(res);
        } else {
          templateListState.panigation.total = res.total;
          templateListState.templateList = res.records;
          activeIndex.value = res.records[0].id;
        }
      });
    };
    // 获取单条模版消息记录
    const getWechatMessageRecordsListFun = () => {
      console.log(state.activeRecord, 1111);
      let data = {
        id: state.activeRecord.id,
        listType: 1,
        ...templateRecordListState.recordPanigation,
        sendTemplateId: state.activeRecord.id,
        appId:templateListState.appId
      };
      wechatApi.getWechatMessageRecordsList({}, data).then(res => {
        if (!res) {
          console.log(res);
        } else {
          templateRecordListState.recordPanigation.total = res.total;
          templateRecordListState.recordList = res.records;
        }
      });
    };
    const handleOk = () => {
      modalVisible.value = false;
      router.push({
        name: 'templateMessageEdit',
        params: {
          id: templateListState.templateContent.id,
          wechatTemplateId: 'add',
        },
      });
    };
    const handlePreview = record => {
      state.activeRecord = JSON.parse(JSON.stringify(record));
      const { tenant, token } = store.state.account;

      state.qrcodeText = `${process.env.VUE_APP_VUE_TO_FORM}/#/wechatTemplateH5?id=${state.activeRecord.id}&wechatTemplateId=${state.activeRecord.wechatTemplateId}&token=${token}&tenant=${tenant}`;

      console.log(state.qrcodeText,111)
      qrcodeModalVisible.value = true;
    };
    const handleCheack = record => {
      recordModalVisible.value = true;
      templateRecordListState.recordList=[];
      state.activeRecord = JSON.parse(JSON.stringify(record));
      getWechatMessageRecordsListFun();
    };
    const handleChange = () => {
      getTemplateListFun();
    };
    const handleChangeTag = vals => {
      state.selectList = state.tagFuneOption.filter(item =>
        vals.includes(item.id)
      );
      console.log(state.selectList);
    };
    const handleChangeSelect = item => {
      if (item.value == 1) {
        wechatApi.getWxTagList({}, templateListState.appId).then(res => {
          if (res) {
            state.tagFuneOption = res;
            state.tagList = res;

            // state.tagFuneOption=[{name:"标签1",id:1},{name:"标签1",id:2}]
          }
        });
      }
    };

    const sendMessage = async () => {
      let params = {
        appId: templateListState.appId,
        sendTemplateId: state.activeRecord.id,
        fanType: sendForm.fanType,
        sendTimeType: sendForm.sendTimeType,
      };
      if (sendForm.sendTimeType == 0) {
        params.sendTime = moment().format('YYYY-MM-DD HH:mm:ss');
      } else {
        params.sendTime = moment(sendForm.sendTime).format(
          'YYYY-MM-DD HH:mm:ss'
        );
      }
      let res = await wechatApi.isAchieveSendThreshold({}, params);
      if (!res) {
        limiteModalVisible.value = true;
        sendModalVisible.value = false;
      } else {
        let data = {
          ...params,
          tagList: state.selectList.map(item => item.tagId),
          templateName: state.activeRecord.templateName,
        };

        if (sendForm.fanType == 1 && state.selectList.length == 0) {
          return message.error('请选择标签');
        }
        wechatApi.sendTemplateBatchRecords({}, data).then(res => {
          if (res) {
            sendModalVisible.value = false;
            successModalVisible.value = true;
          } else {
            message.success('发送失败');
          }
        });
      }
    };
    const cancleSend = record => {
      let data = {
        appId: templateListState.appId,
        sendTemplateId: record.sendTemplateId,
        fanType: sendForm.fanType,
        sendTimeType: sendForm.sendTimeType,
        id: record.id,
        sendFlag: 0,
      };
      wechatApi.cancelTemplateBatchRecords({}, data).then(res => {
        if (res) {
          message.success('取消发送成功');
          getWechatMessageRecordsListFun();
        }
      });
    };
    const exportRecords = record => {
      let data = {
        appId: templateListState.appId,
        batchId: record.id,
        sendTemplateId: record.sendTemplateId,
      };
      wechatApi.exportTemplateFansRecords('', data).then(({ name, data }) => {
        console.log(name);
        downloadExcel(name, data);
      });
    };
    const checkDetail = record => {
      router.push({
        name: 'massDataDetail',
        params: {
          id: record.id,
          sendTemplateId: record.sendTemplateId,
        },
      });
    };
    const deleteTag = id => {
      state.selectList = state.selectList.filter(item => item.id != id);
      state.checkedList = state.checkedList.filter(item => item != id);
    };

    onMounted(() => {
      _init();
    });
    onBeforeUnmount(() => {
      document.removeEventListener('click', handleClickOutside);
    });

    const getDisabledHours = date => {
      const hours = [];
      for (let i = 0; i < 24; i++) {
        if (moment().isAfter(date.clone().hour(i))) {
          hours.push(i);
        }
      }
      return hours;
    };
    const getDisabledMinutes = date => {
      const minutes = [];
      for (let i = 0; i < 60; i++) {
        if (moment().isAfter(date.clone().minute(i))) {
          minutes.push(i);
        }
      }
      return minutes;
    };
    const getDisabledSeconds = date => {
      const seconds = [];
      for (let i = 0; i < 60; i++) {
        if (moment().isAfter(date.clone().second(i))) {
          seconds.push(i);
        }
      }
      return seconds;
    };

    return {
      gridRef,
      modalRef,
      sendForm,
      ...toRefs(state),
      ...toRefs(templateListState),
      ...toRefs(templateRecordListState),
      activeIndex,
      modalVisible,
      modalEmptyVisible,
      recordModalVisible,
      qrcodeModalVisible,
      sendModalVisible,
      limiteModalVisible,
      successModalVisible,
      getTemplateContent,
      pullLatestTemplateFun,
      cancleSend,
      handleOk,
      handlePreview,
      handleChange,
      handleChangeSelect,
      handleChangeTag,
      deleteTag,
      handleCheack,
      exportRecords,
      checkDetail,
      items: [
        { key: 'templateName', label: '消息名称' },
        { key: 'title', label: '模版名称' },

        {
          key: 'creatTime',
          label: '创建时间',
          type: 'rangepicker',
          parmasName: ['startCreateTime', 'endCreateTime'],
        },
      ],
      columns: [
        {
          dataIndex: 'templateName',
          title: '消息名称',
          width: 120,
          ellipsis: true,
        },

        {
          dataIndex: 'title',
          title: '模版名称',
          width: 140,
          ellipsis: true,
        },
        {
          dataIndex: 'templatePreview',
          title: '内容预览',
          width: 120,
          ellipsis: true,
          slots: { customRender: 'templatePreview' },
        },
        {
          dataIndex: 'massSendRecords',
          title: '群发记录',
          width: 180,
          ellipsis: true,
          slots: { customRender: 'massSendRecords' },
        },
        {
          dataIndex: 'createTime',
          title: '创建时间',
          width: 180,
          ellipsis: true,
        },
        {
          key: 'id',
          title: '操作',
          fixed: 'right',
          width: 160,
          slots: { customRender: 'operation' },
        },
      ],
      recordColumns: [
        {
          dataIndex: 'fanType',
          title: '发送对象',
          width: 120,
          ellipsis: true,
          slots: { customRender: 'fanType' },
        },
        {
          dataIndex: 'sendTime',
          title: '发送时间',
          width: 180,
          ellipsis: true,
          slots: { customRender: 'sendTime' },
        },
        {
          dataIndex: 'sendCount',
          title: '发送人数',
          width: 120,
          ellipsis: true,
          slots: { customRender: 'sendCount' },
        },
        {
          dataIndex: 'successCount',
          title: '成功人数',
          width: 120,
          ellipsis: true,
          slots: { customRender: 'successCount' },
        },
        {
          dataIndex: 'successRate',
          title: '成功率',
          width: 120,
          ellipsis: true,
          slots: { customRender: 'successRate' },
        },
        {
          dataIndex: 'sendStatus',
          title: '发送状态',
          width: 180,
          ellipsis: true,
          slots: { customRender: 'sendStatus' },
        },
        {
          key: 'id',
          title: '操作',
          fixed: 'right',
          width: 160,
          slots: { customRender: 'operation' },
        },
      ],
      btnOperation: [
        {
          type: 'add',
          btnType: 'primary',
          permission: 'resources:add',
          fnClick: () => {
            templateListState.panigation.current = 1;

            if (templateListState.templateList.length > 0) {
              modalVisible.value = true;
              getTemplateContent(templateListState.templateList[0]);
            } else {
              wechatApi
                .isSyncRecordsByAppId({}, templateListState.appId)
                .then(res => {
                  console.log(res);
                  // true=>同步过  false=>未同步过
                  if (!res) {
                    isSync.value = false;
                    modalEmptyVisible.value = true;
                  } else {
                    isSync.value = true;
                    modalEmptyVisible.value = true;
                  }
                });
            }
          },
        },
      ],

      options: [
        {
          type: 'copy',
          label: '复制',
          icon: 'CopyOutlined',
          permission: 'wechat:copy',
          fnClick: record => {
            state.activeRecord = JSON.parse(JSON.stringify(record));
            wechatApi
              .copyTemplateList({}, { id: record.id, appId: record.appId })
              .then(res => {
                if (res) {
                  message.success('复制成功！');
                  gridRef.value.refreshGrid();
                }
              });
          },
        },
        {
          type: 'edit',
          label: '编辑',
          icon: 'EditOutlined',
          permission: 'wechat:edit',
          fnClick: record => {
            state.activeRecord = JSON.parse(JSON.stringify(record));
            router.push({
              name: 'templateMessageEdit',
              params: {
                id: record.id,
                wechatTemplateId: state.activeRecord.wechatTemplateId,
              },
            });
          },
        },
        {
          type: 'send',
          label: '发送',
          icon: 'SendOutlined',
          permission: 'wechat:send',
          fnClick: record => {
            state.activeRecord = JSON.parse(JSON.stringify(record));
            (state.checkedList = []),
              (state.selectList = []),
              (sendForm.fanType = 0),
              (sendForm.sendTimeType = 0),
              (sendForm.sendTime = moment().format('YYYY-MM-DD HH:mm:ss')),
              (sendModalVisible.value = true);
          },
        },
        {
          type: 'delete',
          label: '删除',
          permission: 'wechat:delete',
          fnClick: record => {
            wechatApi
              .deleteTemplateList({}, { ids: [record.id], appId: record.appId })
              .then(() => {
                message.success('操作成功！');
                gridRef.value.refreshGrid();
              });
          },
        },
      ],

      recordUrl: wechatApi.recordUrl,
      url: wechatApi.pageUrl,
      gridHeight: document.body.clientHeight - 419,
      handleFnSearch: value => {
        const temp = Object.assign({}, value);
        // temp.tag = temp.tag?.join(',');
        state.search = temp;
      },
      disabledDate: current => {
        // moment().subtract(1, 'days').endOf('day');    moment().endOf('day');
        return current && current < moment().subtract(1, 'days').endOf('day');
      },

      disabledTime(current) {
        return {
          disabledHours: () => getDisabledHours(current || moment(new Date())),
          disabledMinutes: () =>
            getDisabledMinutes(current || moment(new Date())),
          disabledSeconds: () =>
            getDisabledSeconds(current || moment(new Date())),
        };
      },
      debouncedSendMessage() {
        console.log('fasong');
        debounce(sendMessage(), 3000);
      },
      handleTableChange(pagination) {
        templateRecordListState.recordPanigation.current = pagination.current;
        templateRecordListState.recordPanigation.size = pagination.size;
        getWechatMessageRecordsListFun();
      },
    };
  },
};
</script>

<style lang="less" scoped>
.iss-grid-wrap {
  position: relative;
  .refresh-btn {
    position: absolute;
    right: 20px;
    top: 26px;
    cursor: pointer;
  }
  .iss-cotent {
    height: auto;
  }
}
.modal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.templateContent {
  gap: 50px;
  justify-content: space-between;
  align-items: flex-start;
  > div:first-child {
    flex: 0 0 40%;
  }
  .modal-left {
    flex-wrap: wrap;
    .tmp-list {
      width: 45%;
      margin-bottom: 10px;
      :deep(.ant-btn) {
        width: 100%;
        color: #333;

        &:focus {
          color: #85a1ff;
        }
        span {
          display: inline-block;
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
    .active {
      :deep(.ant-btn) {
        color: #85a1ff;
        border-color: #85a1ff;
      }
    }
  }
  .modal-right {
    display: flex;
    align-items: center;
    flex: 1;
    margin-bottom: 32px;

    .main-content {
      width: 100%;
      min-height: 184px;
      border-radius: 7px;
      opacity: 1;
      background: #fff;
      border: 1px solid #e9e9e9;
      padding: 20px 16px 16px 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    :deep(.ant-divider-horizontal) {
      margin: 0 0 14px 0;
    }
  }
}
.operate-btn {
  :deep(.ant-btn-text) {
    color: #85a1ff;
    text-decoration: solid;
  }
}
.pagination {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
:deep(.ant-modal-header) {
  border: none;
}
:deep(.ant-pagination-prev) {
  min-width: 24px;
}
:deep(.ant-pagination-jump-next) {
  min-width: 24px;
}
:deep(.ant-pagination-slash) {
  margin: 0 5px;
}
:deep(.ant-pagination-simple .ant-pagination-simple-pager) {
  margin-right: 0;
}
:deep(.ant-spin) {
  margin-right: 10px;
}
:deep(.ant-radio-inner::after) {
  transform: scale(1);
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  background-color: #999999;
}
:deep(.ant-radio-checked .ant-radio-inner::after) {
  background-color: #5979f8;
}
.tag-select {
  position: relative;

  .select-box {
    width: 100%;
    padding: 10px 16px;
    cursor: pointer;
    color: #a6a6a6;
    font-size: 14px;
    border: 1px solid #e7e7e7;
    height: 32px;
  }
  .tag-list {
    width: 100%;
    position: absolute;
    border-radius: 4px;
    background: #ffffff;
    box-shadow: 0px 0px 6.3px 0px rgba(0, 0, 0, 0.25);
    padding: 16px;
    height: 140px;
    overflow-y: auto;
    z-index: 99;
    margin-top: 10px;
    :deep(.ant-checkbox-wrapper) {
      margin-bottom: 8px;
    }
    :deep(.ant-checkbox-wrapper + .ant-checkbox-wrapper) {
      margin-left: 0;
    }
  }
}

:deep(.ant-time-picker) {
  width: 60%;
}
.send-modal {
  :deep(.ant-form-item) {
    margin-bottom: 12px;
  }
}
</style>
